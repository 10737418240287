@import '../../../style/breakpoints.scss';

.ImportControls {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: var(--spacing-4);
  white-space: nowrap;
  z-index: 90;

  &.-with-image {
    justify-content: center;
    align-items: stretch;

    @media (min-width: $mobile-to-desktop) {
      flex-direction: row;
    }
  }

  &.-with-image &__buttons {
    @media (min-width: $mobile-to-desktop) {
      height: 120px;
    }

    &__buttons {
      @media (min-width: $mobile-to-desktop) {
        flex-direction: column;
      }

      > * + * {
        margin-left: var(--spacing-2);
        margin-top: 0;

        @media (min-width: $mobile-to-desktop) {
          margin-left: 0;
          margin-top: var(--spacing-2);
        }
      }
    }
  }

  @media (min-width: $mobile-to-desktop) {
    top: var(--spacing-3);
  }

  &__title {
    text-transform: uppercase;
    font-size: var(--font-size-5);
  }

  &__list {
    max-width: 100%;
    align-self: center;
    display: flex;
    overflow: auto;
    margin-bottom: var(--spacing-3);
    justify-content: flex-start;

    @media (min-width: $mobile-to-desktop) {
      margin-right: var(--spacing-3);
      margin-bottom: 0;
    }

    &__item {
      --dimension: 80px;
      max-height: var(--dimension);
      padding: 0;
      position: relative;
      font-size: 0;
      padding: var(--spacing-1);
      border: 2px solid transparent;
      outline: none;

      @media (min-width: $mobile-to-desktop) {
        --dimension: 120px;
      }

      & + & {
        margin-left: var(--spacing-1);
      }

      &.-active {
        border-color: var(--base-text-color);
      }

      &__button-overlay {
        display: block;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 50;
      }

      &__remove-button {
        display: flex;
        position: absolute;
        top: var(--spacing-1);
        right: var(--spacing-1);
        padding: var(--spacing-1) !important;
        font-size: var(--font-size-3) !important;
        z-index: 100;
        color: var(--white);
        background-color: hsla(0, 0%, 0%, 0.5) !important;

        &:hover {
          background-color: hsla(0, 0%, 0%, 0.7) !important;
        }
      }

      &__image {
        width: calc(var(--dimension) - 2 * var(--spacing-1) - 4px);
        height: calc(var(--dimension) - 2 * var(--spacing-1) - 4px);
        object-fit: cover;
        object-position: center;
      }
    }
  }


  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__buttons {
      display: flex;
      justify-content: center;
      align-self: center;

      > * + * {
        margin-left: var(--spacing-2);

        @media (min-width: $mobile-to-tablet) {
          margin-left: var(--spacing-4);
        }
      }
    }

    &__title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: var(--font-size-1);
      text-align: center;
      letter-spacing: 1px;
      margin-bottom: var(--spacing-2);
    }
  }

  &__button {
    background-color: hsla(0, 0%, 100%, 0.9);
    border: 1px solid var(--base-text-color);
    color: var(--base-text-color);

    &__device-label::before {
      content: 'Smartphone';

      @media (min-width: $mobile-to-tablet) {
        content: 'Computer';
      }
    }

    &.-change {
      flex: 1;
      font-weight: normal;
    }

    &.-new {
      border-width: 2px;
      letter-spacing: 1px;
      font-size: var(--font-size-3);

      @media (min-width: $mobile-to-tablet) {
        padding: var(--spacing-5) var(--spacing-6);
        font-size: var(--font-size-5);
      }
    }
  }
}
