@import '../../style/breakpoints.scss';

.Confirmation {
  --border-style: 1px solid var(--gray-3);
  margin: var(--spacing-6) auto;
  padding: 0 var(--spacing-6);
  max-width: 620px;

  line-height: 1.5;
  font-size: var(--font-size-4);

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    flex-direction: column;

    text-align: center;
    white-space: nowrap;

    span {
      margin-top: var(--spacing-5);
      color: var(--gray-5);
      font-size: var(--font-size-5);
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-6) 0;

    @media (min-width: $mobile-to-tablet) {
      flex-direction: row;
      align-items: center;
    }

    &__icon {
      margin-right: var(--spacing-5);
      margin-bottom: var(--spacing-5);
      font-size: var(--font-size-9);

      @media (min-width: $mobile-to-tablet) {
        margin-bottom: 0;
      }

      &.-success { color: var(--success-color); }
      &.-error { color: var(--error-color); }
    }
  }

  &__titles {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin: 0;
    font-size: var(--font-size-7);
  }

  &__subtitle {
    margin: 0;
    font-weight: normal;
    font-size: var(--font-size-5);
  }

  &__info {
    padding: var(--spacing-6) 0;
    border-top: var(--border-style);

    &:last-of-type {
      border-bottom: var(--border-style);
      margin-bottom: var(--spacing-6);
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $mobile-to-tablet) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__button {
      margin-top: var(--spacing-5);

      @media (min-width: $mobile-to-tablet) {
        margin-top: 0;
      }
    }
  }
}
