@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.Form {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__error {
    display: flex;
    align-items: center;
    color: var(--error-color);
    padding: var(--spacing-1) 0 var(--spacing-3);
    animation: fade-in .3s;
  }

  label {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--gray-2);

    &:last-child {
      border-bottom: none;
    }

    div {
      display: flex;
      align-items: center;
      padding: var(--spacing-3) 0;

      span {
        color: var(--gray-7);
        min-width: 100px;
      }

      input {
        flex: 1;
        padding: 0 var(--spacing-3);

        &::placeholder {
          color: var(--gray-4);
        }

        &:-webkit-autofill {
          -webkit-text-fill-color: #666ee8;
          -webkit-box-shadow: 0 0 0px 1000px white inset;
        }
      }
    }
  }
}
