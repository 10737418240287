@charset "UTF-8";
.Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--footer-height);
  padding-top: 0 var(--spacing-5);
  font-size: var(--font-size-2);
  color: var(--gray-5);
  z-index: 150;
  background-color: var(--white); }
  @media (min-width: 920px) {
    .Footer {
      right: auto;
      display: block;
      background-color: transparent;
      right: auto;
      padding: var(--spacing-4);
      height: auto; } }
  .Footer__copyright {
    font-weight: bold;
    display: none; }
    @media (min-width: 920px) {
      .Footer__copyright {
        display: inline; } }
  .Footer__link-list__item {
    display: inline; }
    .Footer__link-list__item + .Footer__link-list__item::before {
      margin: 0 var(--spacing-2);
      content: "·"; }
    .Footer__link-list__item a {
      color: inherit; }
      .Footer__link-list__item a:hover {
        color: var(--gray-6);
        text-decoration: underline; }
