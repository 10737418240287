@import '../../../style/breakpoints.scss';

.HomeFooter {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-5);
  overflow: hidden;

  background-color: var(--gray-1);

  @media (min-width: $mobile-to-tablet) {
    padding: 30px 40px;

    content {
      margin-right: 100px;
    }
  }

  @media (min-width: $mobile-to-desktop) {
    padding: 40px 100px;
    flex-direction: row;
  }

  &__copyright {
    font-family: 'Playfair Display';
    color: var(--gray-5);
  }

  menu {
    margin-top: var(--spacing-5);

    @media (min-width: $mobile-to-desktop) {
      margin-top: 0;
      margin-left: var(--spacing-7);
    }

    span {
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      color: var(--gray-7);
      margin-bottom: var(--spacing-4);
    }

    ul {
      li {
        margin-top: var(--spacing-3);

        a {
          color: var(--gray-5);
          transition: color .1s;

          &:hover {
            color: var(--gray-6);
          }
        }
      }
    }
  }
}
