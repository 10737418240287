@import '../../style/breakpoints.scss';

.Editor {
  width: 100%;
  height: 100%;

  @media (min-width: $mobile-to-desktop) {
    height: auto;

    &--sidebar-open {
      padding-right: var(--sidebar-width);
    }
  }

  &__back-button {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    height: 100px;
    width: 100px;
    z-index: 100;

    background-color: transparent;
    box-shadow: none;
    color: var(--gray-6);
    font-weight: normal;
    border-radius: 0;
    transition: color .2s;

    &:hover {
      color: var(--base-text-color);
    }

    @media (min-width: $mobile-to-desktop) {
      display: flex;
    }
  }

  &__sidebar-container {
    width: var(--sidebar-width);
    position: absolute;
    z-index: 200;
    top: 0;
    bottom: 0;
    right: 0;

    @media (min-width: $mobile-to-desktop) {
      border-left: 2px solid var(--gray-2);
    }
  }
}

.fade {
  @media (min-width: $mobile-to-desktop) {
    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity 200ms ease 500ms;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 200ms ease;
      }
    }
  }
}

.slide {
  --delay: 500ms;

  @media (min-width: $mobile-to-desktop) {
    --delay: 0;
  }

  &-enter {
    opacity: 0;
    right: calc(-1 * var(--sidebar-width));

    &-active {
      right: 0;
      opacity: 1;
      transition:
        right 200ms ease var(--delay),
        opacity 200ms ease var(--delay);
    }

    &-done {
      opacity: 1;
      right: 0;
    }
  }

  &-exit {
    right: 0;
    opacity: 1;

    &-active {
      opacity: 0;
      right: calc(-1 * var(--sidebar-width));

      transition:
        right 200ms ease,
        opacity 200ms ease;
    }

    &-done {
      opacity: 0;
      right: calc(-1 * var(--sidebar-width));
    }
  }
}
