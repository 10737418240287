@import url('https://fonts.googleapis.com/css?family=Lato:400,700|Playfair+Display:400');

// systems
@import './style/breakpoints';
@import './style/colors';
@import './style/layout';
@import './style/shadows';
@import './style/text';
@import './style/lib_overrides';

:root {
  --sidebar-width: 100%;
  --footer-height: 45px;
  --header-height: 50px;

  @media (min-width: $mobile-to-desktop) {
    --header-height: 0;
    --sidebar-width: 400px;
  }
}

* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;

  color: var(--base-text-color);
  background-color: var(--white);
  font-size: var(--base-font-size);
}

a {
  text-decoration: none;
  color: var(--highlight-color);
}

button {
  padding: 0;
  border: none;
  background: transparent;
  font: inherit;
  cursor: pointer;
}

p {
  margin: 0;
  padding: 0;
}

input {
  padding: 0;
  font: inherit;
  border: none;
  background: transparent;
  outline: none;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

menu {
  margin: 0;
  padding: 0;
}

.show-sm {
  display: none;

  @media (min-width: $mobile-to-tablet) {
    display: initial;
  }
}
