.CheckoutSidebar {
  &__back-button {
    border: none;
    background-color: var(--gray-2);
    color: var(--gray-5);
  }

  &__agb-consent {
    font-size: var(--font-size-2);
    color: var(--gray-5);
  }

  &__payment-method {
    margin-top: var(--spacing-2);
    color: var(--gray-5);
  }

  &__submit-section {
    text-align: center;
    align-items: center;
    border-bottom: none;
  }

  &__processing-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: var(--spacing-4);
    color: var(--gray-5);

    &__spinner {
      margin-right: var(--spacing-4);
    }
  }
}
