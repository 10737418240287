.Modal {
  &__overlay {
    z-index: 1000;
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: hsla(0, 0%, 0%, 0.9);
    overflow: auto;
  }

  &__content {
    margin: 0 auto;
    color: var(--white);
    outline: 0;

    p {
      margin-bottom: var(--spacing-5);
    }
  }

  &__close-button {
    color: var(--white);
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--spacing-4);

    &:hover {
      color: var(--gray-4);
    }

    svg {
      font-size: var(--font-size-9);
    }
  }
}
