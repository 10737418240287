@import '../../style/breakpoints.scss';

.Sidebar {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--white);

  &__section {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-5) var(--spacing-6);
    border-bottom: 1px solid var(--gray-2);
    position: relative;

    &.-button {
      outline: none;
      flex: 1;

      &:hover {
        background-color: var(--gray-1);
      }

      &.-active {
        color: var(--white);
        background-color: var(--gray-8);
        box-shadow: inset 0 0 5px black;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      margin: 0;

      svg {
        font-size: var(--font-size-6);
      }

      svg + span {
        margin-left: var(--spacing-3);
      }
    }

    &__title + * {
      margin-top: var(--spacing-5);
    }
  }

  &__divider {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--gray-2);
    margin: var(--spacing-3) 0;
  }

  &__line-item {
    display: flex;
    justify-content: space-between;

    & + & {
      margin-top: var(--spacing-3);
    }

    &__label {
      color: var(--gray-5);
    }

    &__price {
      font-weight: bold;
      color: var(--gray-7);
    }
  }

  &__form {
    flex: 1;
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    min-height: min-content;
  }

  &__info {
    color: var(--gray-5);
  }
}
