.PaymentMethodSelector {
  display: flex;
  overflow: auto;
  box-shadow: inset 0 -4px 0 -2px var(--gray-3);
  margin-bottom: var(--spacing-5);

  &__item {
    padding: var(--spacing-2) 0;
    margin-right: var(--spacing-4);
    color: var(--gray-5);
    font-size: var(--font-size-2);
    text-transform: uppercase;
    border-bottom: 2px solid transparent;
    transition: color .2s, border-color .2s;

    input {
      display: none;
    }

    label {
      cursor: pointer;
    }

    &:hover {
      color: var(--gray-7);
    }

    &.-active {
      font-weight: bold;
      color: var(--base-text-color);
      border-color: var(--gray-8);
    }
  }
}
