@import url(https://fonts.googleapis.com/css?family=Lato:400,700|Playfair+Display:400);
:root {
  --white: hsl(0, 0%, 100%);
  --gray-1: hsl(0, 0%, 98%);
  --gray-2: hsl(0, 0%, 95%);
  --gray-3: hsl(0, 0%, 90%);
  --gray-4: hsl(0, 0%, 80%);
  --gray-5: hsl(0, 0%, 66%);
  --gray-6: hsl(0, 0%, 50%);
  --gray-7: hsl(0, 0%, 32%);
  --gray-8: hsl(0, 0%, 20%);
  --gray-9: hsl(0, 0%, 5%);
  --success-color: hsl(97, 76%, 46%);
  --error-color: hsl(0, 91%, 55%);
  --highlight-color: hsl(274, 90%, 45%); }

:root {
  --border-radius: 4px;
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 24px;
  --spacing-6: 32px;
  --spacing-7: 48px;
  --spacing-9: 64px; }

:root {
  --elevation-1: 0 1px 3px hsla(0, 0%, 0%, .2);
  --elevation-2: 0 4px 6px hsla(0, 0%, 0%, .2);
  --elevation-3: 0 5px 15px hsla(0, 0%, 0%, .2);
  --elevation-4: 0 10px 24px hsla(0, 0%, 0%, .2);
  --elevation-5: 0 15px 35px hsla(0, 0%, 0%, .2); }

:root {
  --base-text-color: var(--gray-9);
  --base-font-size: var(--font-size-3);
  --font-size-1: 12px;
  --font-size-2: 14px;
  --font-size-3: 16px;
  --font-size-4: 18px;
  --font-size-5: 20px;
  --font-size-6: 24px;
  --font-size-7: 30px;
  --font-size-8: 36px;
  --font-size-9: 48px;
  --font-size-10: 60px;
  --font-size-11: 72px; }

.rc-slider {
  padding: 24px 0 !important;
  padding: var(--spacing-5) 0 !important; }
  .rc-slider-track {
    background-color: hsl(0, 0%, 66%) !important;
    background-color: var(--gray-5) !important; }
  .rc-slider-handle {
    border-color: hsl(0, 0%, 66%) !important;
    border-color: var(--gray-5) !important; }
    .rc-slider-handle:active {
      box-shadow: 0 0 0 5px hsl(0, 0%, 90%) !important;
      box-shadow: 0 0 0 5px var(--gray-3) !important; }
    .rc-slider-handle:focus {
      box-shadow: 0 0 0 5px hsl(0, 0%, 90%) !important;
      box-shadow: 0 0 0 5px var(--gray-3) !important; }
  .rc-slider-rail {
    background-color: hsl(0, 0%, 90%) !important;
    background-color: var(--gray-3) !important; }

:root {
  --sidebar-width: 100%;
  --footer-height: 45px;
  --header-height: 50px; }
  @media (min-width: 920px) {
    :root {
      --header-height: 0;
      --sidebar-width: 400px; } }

* {
  box-sizing: border-box; }

html, body, #root {
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
  color: hsl(0, 0%, 5%);
  color: var(--base-text-color);
  background-color: hsl(0, 0%, 100%);
  background-color: var(--white);
  font-size: 16px;
  font-size: var(--base-font-size); }

a {
  text-decoration: none;
  color: hsl(274, 90%, 45%);
  color: var(--highlight-color); }

button {
  padding: 0;
  border: none;
  background: transparent;
  font: inherit;
  cursor: pointer; }

p {
  margin: 0;
  padding: 0; }

input {
  padding: 0;
  font: inherit;
  border: none;
  background: transparent;
  outline: none; }

fieldset {
  margin: 0;
  padding: 0;
  border: none; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

menu {
  margin: 0;
  padding: 0; }

.show-sm {
  display: none; }
  @media (min-width: 720px) {
    .show-sm {
      display: inline;
      display: initial; } }

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--base-text-color);
  text-decoration: none;
  font-weight: bold;
  border: 1px solid var(--base-text-color);
  background-color: var(--white);
  outline: 0;
  --icon-text-spacing: var(--spacing-3); }
  .Button.-white {
    border-color: var(--white);
    color: var(--white);
    background-color: transparent; }
  .Button.-plain {
    border: none;
    background: none; }
  .Button.-rounded {
    border-radius: var(--border-radius); }
  .Button.-column {
    flex-direction: column; }
  .Button.-disabled {
    pointer-events: none;
    background-color: var(--gray-2);
    color: var(--gray-6);
    box-shadow: none; }
  .Button svg + span, .Button span + svg {
    margin-left: var(--icon-text-spacing); }
  .Button.-size-1 {
    --icon-text-spacing: var(--spacing-2);
    padding: var(--spacing-3) var(--spacing-4);
    font-size: var(--font-size-3); }
  .Button.-size-2 {
    padding: var(--spacing-3) var(--spacing-5);
    font-size: var(--font-size-4); }
  .Button.-size-3 {
    padding: var(--spacing-3) var(--spacing-5);
    font-size: var(--font-size-5); }

.Confirmation {
  --border-style: 1px solid var(--gray-3);
  margin: var(--spacing-6) auto;
  padding: 0 var(--spacing-6);
  max-width: 620px;
  line-height: 1.5;
  font-size: var(--font-size-4); }
  .Confirmation__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    white-space: nowrap; }
    .Confirmation__loading span {
      margin-top: var(--spacing-5);
      color: var(--gray-5);
      font-size: var(--font-size-5); }
  .Confirmation__header {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-6) 0; }
    @media (min-width: 720px) {
      .Confirmation__header {
        flex-direction: row;
        align-items: center; } }
    .Confirmation__header__icon {
      margin-right: var(--spacing-5);
      margin-bottom: var(--spacing-5);
      font-size: var(--font-size-9); }
      @media (min-width: 720px) {
        .Confirmation__header__icon {
          margin-bottom: 0; } }
      .Confirmation__header__icon.-success {
        color: var(--success-color); }
      .Confirmation__header__icon.-error {
        color: var(--error-color); }
  .Confirmation__titles {
    display: flex;
    flex-direction: column; }
  .Confirmation__title {
    margin: 0;
    font-size: var(--font-size-7); }
  .Confirmation__subtitle {
    margin: 0;
    font-weight: normal;
    font-size: var(--font-size-5); }
  .Confirmation__info {
    padding: var(--spacing-6) 0;
    border-top: var(--border-style); }
    .Confirmation__info:last-of-type {
      border-bottom: var(--border-style);
      margin-bottom: var(--spacing-6); }
  .Confirmation__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media (min-width: 720px) {
      .Confirmation__footer {
        flex-direction: row;
        justify-content: space-between;
        align-items: center; } }
    .Confirmation__footer__button {
      margin-top: var(--spacing-5); }
      @media (min-width: 720px) {
        .Confirmation__footer__button {
          margin-top: 0; } }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.Form {
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  .Form__error {
    display: flex;
    align-items: center;
    color: var(--error-color);
    padding: var(--spacing-1) 0 var(--spacing-3);
    -webkit-animation: fade-in .3s;
            animation: fade-in .3s; }
  .Form label {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--gray-2); }
    .Form label:last-child {
      border-bottom: none; }
    .Form label div {
      display: flex;
      align-items: center;
      padding: var(--spacing-3) 0; }
      .Form label div span {
        color: var(--gray-7);
        min-width: 100px; }
      .Form label div input {
        flex: 1 1;
        padding: 0 var(--spacing-3); }
        .Form label div input::-webkit-input-placeholder {
          color: var(--gray-4); }
        .Form label div input::-ms-input-placeholder {
          color: var(--gray-4); }
        .Form label div input::placeholder {
          color: var(--gray-4); }
        .Form label div input:-webkit-autofill {
          -webkit-text-fill-color: #666ee8;
          -webkit-box-shadow: 0 0 0px 1000px white inset; }

.PaymentMethodSelector {
  display: flex;
  overflow: auto;
  box-shadow: inset 0 -4px 0 -2px var(--gray-3);
  margin-bottom: var(--spacing-5); }
  .PaymentMethodSelector__item {
    padding: var(--spacing-2) 0;
    margin-right: var(--spacing-4);
    color: var(--gray-5);
    font-size: var(--font-size-2);
    text-transform: uppercase;
    border-bottom: 2px solid transparent;
    transition: color .2s, border-color .2s; }
    .PaymentMethodSelector__item input {
      display: none; }
    .PaymentMethodSelector__item label {
      cursor: pointer; }
    .PaymentMethodSelector__item:hover {
      color: var(--gray-7); }
    .PaymentMethodSelector__item.-active {
      font-weight: bold;
      color: var(--base-text-color);
      border-color: var(--gray-8); }

.Sidebar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--white); }
  .Sidebar__section {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-5) var(--spacing-6);
    border-bottom: 1px solid var(--gray-2);
    position: relative; }
    .Sidebar__section.-button {
      outline: none;
      flex: 1 1; }
      .Sidebar__section.-button:hover {
        background-color: var(--gray-1); }
      .Sidebar__section.-button.-active {
        color: var(--white);
        background-color: var(--gray-8);
        box-shadow: inset 0 0 5px black; }
    .Sidebar__section__title {
      display: flex;
      align-items: center;
      margin: 0; }
      .Sidebar__section__title svg {
        font-size: var(--font-size-6); }
      .Sidebar__section__title svg + span {
        margin-left: var(--spacing-3); }
    .Sidebar__section__title + * {
      margin-top: var(--spacing-5); }
  .Sidebar__divider {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--gray-2);
    margin: var(--spacing-3) 0; }
  .Sidebar__line-item {
    display: flex;
    justify-content: space-between; }
    .Sidebar__line-item + .Sidebar__line-item {
      margin-top: var(--spacing-3); }
    .Sidebar__line-item__label {
      color: var(--gray-5); }
    .Sidebar__line-item__price {
      font-weight: bold;
      color: var(--gray-7); }
  .Sidebar__form {
    flex: 1 1;
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    min-height: -webkit-min-content;
    min-height: -moz-min-content;
    min-height: min-content; }
  .Sidebar__info {
    color: var(--gray-5); }

.DiscountCode {
  padding: var(--spacing-3);
  margin-bottom: var(--spacing-3);
  border-radius: 3px;
  border: 1px solid var(--gray-3);
  align-self: center;
  text-align: center;
  width: 130px;
  font-weight: bold;
  font-size: var(--font-size-2);
  background-color: var(--white);
  transition: background-color .2s; }
  .DiscountCode::-webkit-input-placeholder {
    color: var(--gray-5);
    font-weight: normal; }
  .DiscountCode::-ms-input-placeholder {
    color: var(--gray-5);
    font-weight: normal; }
  .DiscountCode::placeholder {
    color: var(--gray-5);
    font-weight: normal; }
  .DiscountCode--valid {
    background-color: var(--success-color);
    border-color: transparent;
    color: white; }
  .DiscountCode--invalid {
    background-color: var(--error-color);
    border-color: transparent;
    color: white; }

.ErrorText {
  color: var(--error-color); }

.PurchaseButton {
  background-color: var(--highlight-color);
  border: none;
  color: var(--white);
  border-radius: var(--border-radius); }

.CheckoutSidebar__back-button {
  border: none;
  background-color: var(--gray-2);
  color: var(--gray-5); }

.CheckoutSidebar__agb-consent {
  font-size: var(--font-size-2);
  color: var(--gray-5); }

.CheckoutSidebar__payment-method {
  margin-top: var(--spacing-2);
  color: var(--gray-5); }

.CheckoutSidebar__submit-section {
  text-align: center;
  align-items: center;
  border-bottom: none; }

.CheckoutSidebar__processing-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: var(--spacing-4);
  color: var(--gray-5); }
  .CheckoutSidebar__processing-info__spinner {
    margin-right: var(--spacing-4); }

.FormatSelector {
  display: flex;
  justify-content: center; }
  .FormatSelector__item {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    font-weight: normal;
    cursor: inherit;
    height: 140px;
    padding: var(--spacing-3) var(--spacing-2) !important;
    font-size: var(--font-size-2) !important; }
    @media (min-width: 720px) {
      .FormatSelector__item {
        height: 180px;
        font-size: var(--font-size-3) !important; } }
    .FormatSelector__item.-active {
      font-weight: bold; }
    .FormatSelector__item:not(.-active):hover {
      background-color: var(--gray-2);
      cursor: pointer; }
    .FormatSelector__item__image-container {
      position: relative;
      margin-bottom: var(--spacing-4); }
    .FormatSelector__item__image {
      height: 60px;
      width: auto;
      box-shadow: var(--elevation-5); }
      @media (min-width: 720px) {
        .FormatSelector__item__image {
          height: 100px; } }
    .FormatSelector__item__active-indicator {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      font-size: var(--font-size-6); }

.OverlayControls {
  display: flex;
  flex-direction: column;
  z-index: 90;
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(var(--footer-height));
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: var(--white); }
  @media (min-width: 920px) {
    .OverlayControls {
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      max-width: 600px;
      bottom: var(--spacing-6);
      border-radius: 5px; } }
  .OverlayControls--has-active .OverlayControls__button {
    color: var(--gray-4); }
  .OverlayControls__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: color .2s;
    outline: 0;
    -webkit-tap-highlight-color: transparent; }
    .OverlayControls__button--active {
      color: var(--base-text-color) !important; }
    .OverlayControls__button + .OverlayControls__button {
      margin-left: var(--spacing-5); }
    .OverlayControls__button svg {
      font-size: var(--font-size-7);
      margin-bottom: var(--spacing-2); }
    .OverlayControls__button span {
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: -0.5pt;
      font-size: var(--font-size-1); }
  .OverlayControls__control {
    padding: 0 var(--spacing-5);
    border-bottom: 1px solid var(--gray-3); }
    .OverlayControls__control__title {
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: -0.5pt;
      margin-bottom: var(--spacing-4); }
  .OverlayControls__buttons {
    display: flex;
    align-items: center;
    padding: 0 var(--spacing-5); }
    .OverlayControls__buttons__left, .OverlayControls__buttons__right {
      padding: var(--spacing-4) 0; }
    .OverlayControls__buttons__left {
      display: flex;
      overflow: auto;
      align-items: center;
      margin-right: var(--spacing-5); }
    .OverlayControls__buttons__right {
      margin-left: auto; }
  .OverlayControls__submit-button {
    background-color: transparent;
    color: var(--gray-9);
    box-shadow: none;
    border: 1px solid var(--gray-9);
    border-radius: 0; }
    .OverlayControls__submit-button.-disabled {
      background-color: transparent;
      border-color: var(--gray-3);
      color: var(--gray-3); }
    .OverlayControls__submit-button span {
      display: none; }
    @media (min-width: 920px) {
      .OverlayControls__submit-button span {
        display: inline; } }

.Modal__overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: auto; }

.Modal__content {
  margin: 0 auto;
  color: var(--white);
  outline: 0; }
  .Modal__content p {
    margin-bottom: var(--spacing-5); }

.Modal__close-button {
  color: var(--white);
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--spacing-4); }
  .Modal__close-button:hover {
    color: var(--gray-4); }
  .Modal__close-button svg {
    font-size: var(--font-size-9); }

.InstagramSelectionModal__overlay {
  -webkit-animation: fade-in .5s;
          animation: fade-in .5s; }

.InstagramSelectionModal__content {
  position: relative;
  opacity: 0;
  -webkit-animation: fade-in .5s .5s forwards;
          animation: fade-in .5s .5s forwards;
  overflow: auto;
  width: 100%;
  max-width: 900px;
  margin-top: var(--spacing-5);
  padding: var(--spacing-5); }
  @media (min-width: 720px) {
    .InstagramSelectionModal__content {
      margin-top: 0;
      padding: var(--spacing-7); } }

.InstagramSelectionModal__title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-6); }
  @media (min-width: 720px) {
    .InstagramSelectionModal__title {
      font-size: var(--font-size-7); } }
  .InstagramSelectionModal__title img {
    --dimension: 30px;
    margin-right: var(--spacing-4);
    width: var(--dimension);
    height: var(--dimension); }
    @media (min-width: 720px) {
      .InstagramSelectionModal__title img {
        --dimension: 40px; } }

.InstagramSelectionModal__disclaimer {
  color: var(--gray-5);
  font-size: var(--font-size-3);
  text-align: center; }

.InstagramSelectionModal__submit-button {
  position: fixed;
  right: var(--spacing-5);
  bottom: var(--spacing-5); }

.InstagramSelectionModal__list {
  --item-width: 140px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--item-width), 1fr));
  grid-gap: var(--spacing-4);
  width: 100%; }
  @media (min-width: 720px) {
    .InstagramSelectionModal__list {
      --item-width: 250px; } }
  .InstagramSelectionModal__list__item {
    display: flex;
    position: relative;
    overflow: hidden;
    outline: 0; }
    .InstagramSelectionModal__list__item img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .InstagramSelectionModal__list__item__selection {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 100; }
      .InstagramSelectionModal__list__item__selection svg {
        font-size: var(--font-size-7);
        text-shadow: var(--elevation-5);
        color: var(--white); }
    .InstagramSelectionModal__list__item__overlay {
      z-index: 50;
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      align-items: center;
      justify-content: center;
      font-size: var(--font-size-4);
      color: var(--white);
      font-weight: bold; }
    .InstagramSelectionModal__list__item:hover .InstagramSelectionModal__list__item__overlay {
      display: flex; }

.ImportControls {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: var(--spacing-4);
  white-space: nowrap;
  z-index: 90; }
  .ImportControls.-with-image {
    justify-content: center;
    align-items: stretch; }
    @media (min-width: 920px) {
      .ImportControls.-with-image {
        flex-direction: row; } }
  @media (min-width: 920px) {
    .ImportControls.-with-image .ImportControls__buttons {
      height: 120px; } }
  @media (min-width: 920px) {
    .ImportControls.-with-image .ImportControls__buttons__buttons {
      flex-direction: column; } }
  .ImportControls.-with-image .ImportControls__buttons__buttons > * + * {
    margin-left: var(--spacing-2);
    margin-top: 0; }
    @media (min-width: 920px) {
      .ImportControls.-with-image .ImportControls__buttons__buttons > * + * {
        margin-left: 0;
        margin-top: var(--spacing-2); } }
  @media (min-width: 920px) {
    .ImportControls {
      top: var(--spacing-3); } }
  .ImportControls__title {
    text-transform: uppercase;
    font-size: var(--font-size-5); }
  .ImportControls__list {
    max-width: 100%;
    align-self: center;
    display: flex;
    overflow: auto;
    margin-bottom: var(--spacing-3);
    justify-content: flex-start; }
    @media (min-width: 920px) {
      .ImportControls__list {
        margin-right: var(--spacing-3);
        margin-bottom: 0; } }
    .ImportControls__list__item {
      --dimension: 80px;
      max-height: var(--dimension);
      padding: 0;
      position: relative;
      font-size: 0;
      padding: var(--spacing-1);
      border: 2px solid transparent;
      outline: none; }
      @media (min-width: 920px) {
        .ImportControls__list__item {
          --dimension: 120px; } }
      .ImportControls__list__item + .ImportControls__list__item {
        margin-left: var(--spacing-1); }
      .ImportControls__list__item.-active {
        border-color: var(--base-text-color); }
      .ImportControls__list__item__button-overlay {
        display: block;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 50; }
      .ImportControls__list__item__remove-button {
        display: flex;
        position: absolute;
        top: var(--spacing-1);
        right: var(--spacing-1);
        padding: var(--spacing-1) !important;
        font-size: var(--font-size-3) !important;
        z-index: 100;
        color: var(--white);
        background-color: rgba(0, 0, 0, 0.5) !important; }
        .ImportControls__list__item__remove-button:hover {
          background-color: rgba(0, 0, 0, 0.7) !important; }
      .ImportControls__list__item__image {
        width: calc(var(--dimension) - 2 * var(--spacing-1) - 4px);
        height: calc(var(--dimension) - 2 * var(--spacing-1) - 4px);
        object-fit: cover;
        object-position: center; }
  .ImportControls__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .ImportControls__buttons__buttons {
      display: flex;
      justify-content: center;
      align-self: center; }
      .ImportControls__buttons__buttons > * + * {
        margin-left: var(--spacing-2); }
        @media (min-width: 720px) {
          .ImportControls__buttons__buttons > * + * {
            margin-left: var(--spacing-4); } }
    .ImportControls__buttons__title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: var(--font-size-1);
      text-align: center;
      letter-spacing: 1px;
      margin-bottom: var(--spacing-2); }
  .ImportControls__button {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid var(--base-text-color);
    color: var(--base-text-color); }
    .ImportControls__button__device-label::before {
      content: 'Smartphone'; }
      @media (min-width: 720px) {
        .ImportControls__button__device-label::before {
          content: 'Computer'; } }
    .ImportControls__button.-change {
      flex: 1 1;
      font-weight: normal; }
    .ImportControls__button.-new {
      border-width: 2px;
      letter-spacing: 1px;
      font-size: var(--font-size-3); }
      @media (min-width: 720px) {
        .ImportControls__button.-new {
          padding: var(--spacing-5) var(--spacing-6);
          font-size: var(--font-size-5); } }

@charset "UTF-8";
.Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--footer-height);
  padding-top: 0 var(--spacing-5);
  font-size: var(--font-size-2);
  color: var(--gray-5);
  z-index: 150;
  background-color: var(--white); }
  @media (min-width: 920px) {
    .Footer {
      right: auto;
      display: block;
      background-color: transparent;
      right: auto;
      padding: var(--spacing-4);
      height: auto; } }
  .Footer__copyright {
    font-weight: bold;
    display: none; }
    @media (min-width: 920px) {
      .Footer__copyright {
        display: inline; } }
  .Footer__link-list__item {
    display: inline; }
    .Footer__link-list__item + .Footer__link-list__item::before {
      margin: 0 var(--spacing-2);
      content: "\B7"; }
    .Footer__link-list__item a {
      color: inherit; }
      .Footer__link-list__item a:hover {
        color: var(--gray-6);
        text-decoration: underline; }

.GLCanvas__canvas {
  opacity: 0; }
  .GLCanvas__canvas--initialized {
    transition: opacity 1s;
    opacity: 1; }
  .GLCanvas__canvas--reloading {
    transition: opacity 0s;
    opacity: 0; }

.GLCanvas__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  white-space: nowrap; }
  .GLCanvas__loading span {
    margin-top: var(--spacing-5);
    color: var(--gray-5);
    font-size: var(--font-size-5); }

.Editor {
  width: 100%;
  height: 100%; }
  @media (min-width: 920px) {
    .Editor {
      height: auto; }
      .Editor--sidebar-open {
        padding-right: var(--sidebar-width); } }
  .Editor__back-button {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    height: 100px;
    width: 100px;
    z-index: 100;
    background-color: transparent;
    box-shadow: none;
    color: var(--gray-6);
    font-weight: normal;
    border-radius: 0;
    transition: color .2s; }
    .Editor__back-button:hover {
      color: var(--base-text-color); }
    @media (min-width: 920px) {
      .Editor__back-button {
        display: flex; } }
  .Editor__sidebar-container {
    width: var(--sidebar-width);
    position: absolute;
    z-index: 200;
    top: 0;
    bottom: 0;
    right: 0; }
    @media (min-width: 920px) {
      .Editor__sidebar-container {
        border-left: 2px solid var(--gray-2); } }

@media (min-width: 920px) {
  .fade-enter {
    opacity: 0; }
    .fade-enter-active {
      opacity: 1;
      transition: opacity 200ms ease 500ms; }
  .fade-exit {
    opacity: 1; }
    .fade-exit-active {
      opacity: 0;
      transition: opacity 200ms ease; } }

.slide {
  --delay: 500ms; }
  @media (min-width: 920px) {
    .slide {
      --delay: 0; } }
  .slide-enter {
    opacity: 0;
    right: calc(-1 * var(--sidebar-width)); }
    .slide-enter-active {
      right: 0;
      opacity: 1;
      transition: right 200ms ease var(--delay), opacity 200ms ease var(--delay); }
    .slide-enter-done {
      opacity: 1;
      right: 0; }
  .slide-exit {
    right: 0;
    opacity: 1; }
    .slide-exit-active {
      opacity: 0;
      right: calc(-1 * var(--sidebar-width));
      transition: right 200ms ease, opacity 200ms ease; }
    .slide-exit-done {
      opacity: 0;
      right: calc(-1 * var(--sidebar-width)); }

.App {
  display: flex;
  flex-direction: column;
  height: calc(100%);
  overflow: hidden;
  position: relative; }

.Slider {
  order: 40;
  width: 100%;
  max-width: 300px;
  position: relative;
  height: 300px; }
  @media (min-width: 720px) {
    .Slider {
      max-width: 650px;
      width: 650px;
      height: 100%;
      -webkit-transform: perspective(3000px) rotateY(-29deg) rotateX(4deg) rotateZ(1deg);
              transform: perspective(3000px) rotateY(-29deg) rotateX(4deg) rotateZ(1deg); } }
  .Slider__image {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 100%;
    position: absolute;
    height: auto;
    opacity: 0;
    transition: opacity .5s;
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025); }
    @media (min-width: 720px) {
      .Slider__image {
        box-shadow: 1px 1px 5px 0 rgba(26, 26, 67, 0.05), 39px 62.5px 125px -25px rgba(50, 50, 93, 0.5), 23.4px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6); } }
    .Slider__image--active {
      opacity: 1; }
  .Slider__arrow {
    color: var(--gray-3);
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    position: absolute;
    font-size: var(--font-size-10);
    transition: color .2s;
    z-index: 100;
    outline: none;
    display: none; }
    @media (min-width: 720px) {
      .Slider__arrow {
        display: block; } }
    .Slider__arrow:active, .Slider__arrow:focus, .Slider__arrow :hover {
      color: var(--gray-4); }
    .Slider__arrow--left {
      left: -100px; }
    .Slider__arrow--right {
      right: -100px; }

.StartSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding: var(--spacing-5);
  overflow: hidden;
  background-color: var(--white);
  font-family: 'Playfair Display'; }
  @media (min-width: 720px) {
    .StartSection {
      padding-left: 40px;
      padding-right: 80px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .StartSection content {
        margin-right: 100px; } }
  @media (min-width: 920px) {
    .StartSection {
      padding-left: 100px;
      padding-right: 120px; } }
  .StartSection__button {
    order: 30;
    border-radius: 0;
    border: 1px solid var(--base-text-color);
    color: var(--base-text-color);
    background-color: transparent;
    box-shadow: none;
    font-weight: lighter;
    margin-top: var(--spacing-6); }
  .StartSection h1, .StartSection h2 {
    font-weight: normal;
    text-align: center; }
    @media (min-width: 720px) {
      .StartSection h1, .StartSection h2 {
        text-align: left; } }
  .StartSection h1 {
    order: 0;
    margin: 0;
    font-size: var(--font-size-8);
    white-space: nowrap;
    line-height: 1.2; }
    @media (min-width: 720px) {
      .StartSection h1 {
        margin-bottom: var(--spacing-2);
        font-size: var(--font-size-9); } }
    @media (min-width: 920px) {
      .StartSection h1 {
        font-size: var(--font-size-10); } }
  .StartSection h2 {
    order: 10;
    font-size: var(--font-size-5); }
    @media (min-width: 720px) {
      .StartSection h2 {
        font-size: var(--font-size-6); } }
  .StartSection p {
    order: 20;
    font-size: var(--font-size-4);
    color: var(--gray-6);
    text-align: center; }
    @media (min-width: 720px) {
      .StartSection p {
        text-align: left;
        font-size: var(--font-size-5);
        width: 100%;
        max-width: 500px; } }

.HomeFooter {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-5);
  overflow: hidden;
  background-color: var(--gray-1); }
  @media (min-width: 720px) {
    .HomeFooter {
      padding: 30px 40px; }
      .HomeFooter content {
        margin-right: 100px; } }
  @media (min-width: 920px) {
    .HomeFooter {
      padding: 40px 100px;
      flex-direction: row; } }
  .HomeFooter__copyright {
    font-family: 'Playfair Display';
    color: var(--gray-5); }
  .HomeFooter menu {
    margin-top: var(--spacing-5); }
    @media (min-width: 920px) {
      .HomeFooter menu {
        margin-top: 0;
        margin-left: var(--spacing-7); } }
    .HomeFooter menu span {
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      color: var(--gray-7);
      margin-bottom: var(--spacing-4); }
    .HomeFooter menu ul li {
      margin-top: var(--spacing-3); }
      .HomeFooter menu ul li a {
        color: var(--gray-5);
        transition: color .1s; }
        .HomeFooter menu ul li a:hover {
          color: var(--gray-6); }

.Home {
  height: 100%;
  max-width: 1500px;
  margin: 0 auto; }

