@import '../../style/breakpoints.scss';

.OverlayControls {
  display: flex;
  flex-direction: column;
  z-index: 90;
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(var(--footer-height));
  user-select: none;
  background-color: var(--white);

  @media (min-width: $mobile-to-desktop) {
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    bottom: var(--spacing-6);
    border-radius: 5px;
  }

  &--has-active &__button {
    color: var(--gray-4);
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: color .2s;
    outline: 0;
    -webkit-tap-highlight-color: transparent;

    &--active {
      color: var(--base-text-color) !important;
    }

    & + & {
      margin-left: var(--spacing-5);
    }

    svg {
      font-size: var(--font-size-7);
      margin-bottom: var(--spacing-2);
    }

    span {
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: -0.5pt;
      font-size: var(--font-size-1);
    }
  }

  &__control {
    padding: 0 var(--spacing-5);
    border-bottom: 1px solid var(--gray-3);

    &__title {
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: -0.5pt;
      margin-bottom: var(--spacing-4);
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    padding: 0 var(--spacing-5);

    &__left, &__right {
      padding: var(--spacing-4) 0;
    }

    &__left {
      display: flex;
      overflow: auto;
      align-items: center;
      margin-right: var(--spacing-5);
    }

    &__right {
      margin-left: auto;
    }
  }

  &__submit-button {
    background-color: transparent;
    color: var(--gray-9);
    box-shadow: none;
    border: 1px solid var(--gray-9);
    border-radius: 0;

    &.-disabled {
      background-color: transparent;
      border-color: var(--gray-3);
      color: var(--gray-3);
    }

    span { display: none; }

    @media (min-width: $mobile-to-desktop) {
      span { display: inline; }
    }
  }
}
