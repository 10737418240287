.rc-slider {
  padding: var(--spacing-5) 0 !important;

  &-track {
    background-color: var(--gray-5) !important;
  }

  &-handle {
    border-color: var(--gray-5) !important;

    &:active {
      box-shadow: 0 0 0 5px var(--gray-3) !important;
    }

    &:focus {
      box-shadow: 0 0 0 5px var(--gray-3) !important;
    }
  }

  &-rail {
    background-color: var(--gray-3) !important;
  }
}
