@import '../../../style/breakpoints.scss';

.Slider {
  order: 40;
  width: 100%;
  max-width: 300px;
  position: relative;
  height: 300px;

  @media (min-width: $mobile-to-tablet) {
    max-width: 650px;
    width: 650px;
    height: 100%;
    transform:
      perspective(3000px)
      rotateY(-29deg)
      rotateX(4deg)
      rotateZ(1deg);
  }

  &__image {
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    position: absolute;
    height: auto;
    opacity: 0;
    transition: opacity .5s;

    box-shadow:
      0 30px 60px -12px rgba(50, 50, 93, .25),
      0 18px 36px -18px rgba(0, 0, 0, .3),
      0 -12px 36px -8px rgba(0, 0, 0, .025);

    @media (min-width: $mobile-to-tablet) {
      box-shadow:
        1px 1px 5px 0 rgba(26, 26, 67, .05),
        39px 62.5px 125px -25px rgba(50, 50, 93, .5),
        23.4px 37.5px 75px -37.5px rgba(0, 0, 0, .6);
    }

    &--active {
      opacity: 1;
    }
  }

  &__arrow {
    color: var(--gray-3);
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    font-size: var(--font-size-10);
    transition: color .2s;
    z-index: 100;
    outline: none;
    display: none;

    @media (min-width: $mobile-to-tablet) {
      display: block;
    }

    &:active, &:focus, :hover {
      color: var(--gray-4);
    }

    &--left {
      left: -100px;
    }

    &--right {
      right: -100px;
    }
  }
}
