@import '../../../style/breakpoints.scss';

.StartSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding: var(--spacing-5);
  overflow: hidden;

  background-color: var(--white);
  font-family: 'Playfair Display';

  @media (min-width: $mobile-to-tablet) {
    padding-left: 40px;
    padding-right: 80px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    content {
      margin-right: 100px;
    }
  }

  @media (min-width: $mobile-to-desktop) {
    padding-left: 100px;
    padding-right: 120px;
  }

  &__button {
    order: 30;
    border-radius: 0;
    border: 1px solid var(--base-text-color);
    color: var(--base-text-color);
    background-color: transparent;
    box-shadow: none;
    font-weight: lighter;
    margin-top: var(--spacing-6);
  }

  h1, h2 {
    font-weight: normal;
    text-align: center;

    @media (min-width: $mobile-to-tablet) {
      text-align: left;
    }
  }

  h1 {
    order: 0;
    margin: 0;
    font-size: var(--font-size-8);
    white-space: nowrap;
    line-height: 1.2;

    @media (min-width: $mobile-to-tablet) {
      margin-bottom: var(--spacing-2);
      font-size: var(--font-size-9);
    }

    @media (min-width: $mobile-to-desktop) {
      font-size: var(--font-size-10);
    }
  }

  h2 {
    order: 10;
    font-size: var(--font-size-5);

    @media (min-width: $mobile-to-tablet) {
      font-size: var(--font-size-6);
    }
  }

  p {
    order: 20;
    font-size: var(--font-size-4);
    color: var(--gray-6);
    text-align: center;

    @media (min-width: $mobile-to-tablet) {
      text-align: left;
      font-size: var(--font-size-5);
      width: 100%;
      max-width: 500px;
    }
  }
}
