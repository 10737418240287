@import '../../../style/breakpoints.scss';

.InstagramSelectionModal {
  &__overlay {
    animation: fade-in .5s;
  }

  &__content {
    position: relative;
    opacity: 0;
    animation: fade-in .5s .5s forwards;
    overflow: auto;
    width: 100%;
    max-width: 900px;
    margin-top: var(--spacing-5);
    padding: var(--spacing-5);

    @media (min-width: $mobile-to-tablet) {
      margin-top: 0;
      padding: var(--spacing-7);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--font-size-6);

    @media (min-width: $mobile-to-tablet) {
      font-size: var(--font-size-7);
    }

    img {
      --dimension: 30px;
      margin-right: var(--spacing-4);
      width: var(--dimension);
      height: var(--dimension);

      @media (min-width: $mobile-to-tablet) {
        --dimension: 40px;
      }
    }
  }

  &__disclaimer {
    color: var(--gray-5);
    font-size: var(--font-size-3);
    text-align: center;
  }

  &__submit-button {
    position: fixed;
    right: var(--spacing-5);
    bottom: var(--spacing-5);
  }

  &__list {
    --item-width: 140px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--item-width), 1fr));
    grid-gap: var(--spacing-4);
    width: 100%;

    @media (min-width: $mobile-to-tablet) {
      --item-width: 250px;
    }

    &__item {
      display: flex;
      position: relative;
      overflow: hidden;
      outline: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &__selection {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 100;

        svg {
          font-size: var(--font-size-7);
          text-shadow: var(--elevation-5);
          color: var(--white);
        }
      }

      &__overlay {
        z-index: 50;
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: hsla(0, 0%, 0%, 0.6);
        align-items: center;
        justify-content: center;
        font-size: var(--font-size-4);
        color: var(--white);
        font-weight: bold;
      }

      &:hover &__overlay {
        display: flex;
      }
    }
  }
}
