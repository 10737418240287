.DiscountCode {
  padding: var(--spacing-3);
  margin-bottom: var(--spacing-3);
  border-radius: 3px;
  border: 1px solid var(--gray-3);
  align-self: center;
  text-align: center;
  width: 130px;
  font-weight: bold;
  font-size: var(--font-size-2);
  background-color: var(--white);
  transition: background-color .2s;

  &::placeholder {
    color: var(--gray-5);
    font-weight: normal;
  }

  &--valid {
    background-color: var(--success-color);
    border-color: transparent;
    color: white;
  }

  &--invalid {
    background-color: var(--error-color);
    border-color: transparent;
    color: white;
  }
}
