@import '../../style/breakpoints';

.FormatSelector {
  display: flex;
  justify-content: center;

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    font-weight: normal;
    cursor: inherit;

    height: 140px;
    padding: var(--spacing-3) var(--spacing-2) !important;
    font-size: var(--font-size-2) !important;

    @media (min-width: $mobile-to-tablet) {
      height: 180px;
      font-size: var(--font-size-3) !important;
    }

    &.-active {
      font-weight: bold;
    }

    &:not(.-active):hover {
      background-color: var(--gray-2);
      cursor: pointer;
    }

    &__image-container {
      position: relative;
      margin-bottom: var(--spacing-4);
    }

    &__image {
      height: 60px;
      width: auto;
      box-shadow: var(--elevation-5);

      @media (min-width: $mobile-to-tablet) {
        height: 100px;
      }
    }

    &__active-indicator {
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: var(--font-size-6);
    }
  }
}
