.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: var(--base-text-color);
  text-decoration: none;
  font-weight: bold;
  border: 1px solid var(--base-text-color);
  background-color: var(--white);
  outline: 0;

  --icon-text-spacing: var(--spacing-3);

  &.-white {
    border-color: var(--white);
    color: var(--white);
    background-color: transparent;
  }

  &.-plain {
    border: none;
    background: none;
  }

  &.-rounded {
    border-radius: var(--border-radius);
  }

  &.-column {
    flex-direction: column;
  }

  &.-disabled {
    pointer-events: none;
    background-color: var(--gray-2);
    color: var(--gray-6);
    box-shadow: none;
  }

  svg + span, span + svg {
    margin-left: var(--icon-text-spacing);
  }

  &.-size-1 {
    --icon-text-spacing: var(--spacing-2);
    padding: var(--spacing-3) var(--spacing-4);
    font-size: var(--font-size-3);
  }

  &.-size-2 {
    padding: var(--spacing-3) var(--spacing-5);
    font-size: var(--font-size-4);
  }

  &.-size-3 {
    padding: var(--spacing-3) var(--spacing-5);
    font-size: var(--font-size-5);
  }
}
