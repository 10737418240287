@import '../../style/breakpoints.scss';

.GLCanvas {
  &__canvas {
    opacity: 0;

    &--initialized {
      transition: opacity 1s;
      opacity: 1;
    }

    &--reloading {
      transition: opacity 0s;
      opacity: 0;
    }
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    flex-direction: column;

    text-align: center;
    white-space: nowrap;

    span {
      margin-top: var(--spacing-5);
      color: var(--gray-5);
      font-size: var(--font-size-5);
    }
  }
}
