:root {
  // greys
  --white: hsl(0, 0%, 100%);

  --gray-1: hsl(0, 0%, 98%);
  --gray-2: hsl(0, 0%, 95%);
  --gray-3: hsl(0, 0%, 90%);
  --gray-4: hsl(0, 0%, 80%);
  --gray-5: hsl(0, 0%, 66%);
  --gray-6: hsl(0, 0%, 50%);
  --gray-7: hsl(0, 0%, 32%);
  --gray-8: hsl(0, 0%, 20%);
  --gray-9: hsl(0, 0%, 5%);

  --success-color: hsl(97, 76%, 46%);
  --error-color: hsl(0, 91%, 55%);

  --highlight-color: hsl(274, 90%, 45%);
}
